import React from 'react';
import { Popover } from '@material-ui/core';
import PropTypes from 'prop-types';

import styles from './Popover.module.scss';

/**
 * # Popover
 * Simple ResMed Popover Component
 *
 * ## PropTypes:
 * ```js
 *  RMPopover.propTypes = {
 *    id: PropTypes.string.isRequired,
 *    children: PropTypes.node,
 *    anchorEl: PropTypes.oneOfType([PropTypes.object, PropTypes.node, PropTypes.func]),
 *   *  };
 * ```
 */

const RMPopover = ({
  id, anchorEl, children, ...props
}) => (
  <Popover
    id={id}
    disableScrollLock
    anchorEl={anchorEl}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'left',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'left',
    }}
    className={styles.Popover}
    {...props}
  >
    {children}
  </Popover>
);

RMPopover.propTypes = {
  id: PropTypes.string,
  children: PropTypes.node,
  anchorEl: PropTypes.oneOfType([PropTypes.object, PropTypes.node, PropTypes.func]),
};

export default RMPopover;
