import _ from 'lodash';

const INCH_TO_CM_MULTIPLIER = 2.5;

/**
 * Calculates the deadspace based on height
 * Anatomical Deadspace is the volume of upper airway that does
 * not participate in gas exchange. It is estimated from patient height.
 * @param heightInCm
 * @returns Value is rounded to nearest 1ml
 */
export const deadspace = (heightInCm, rounded = true) => {
  const deadspaceValue = 120 * ((parseInt(heightInCm, 10) / 175) ** 2.363);
  return rounded ? round(deadspaceValue, 0) : deadspaceValue;
};

/**
 *
 * @param heightInCm
 * @returns Ideal body weight in kg to one decimal point, calculated from height in centimeters
 */

export const idealBodyWeightFromCm = (heightInCm, roundOutput = true) => {
  let idealWeight;
  const heightCm = parseInt(heightInCm, 10);
  const heightInMeters = heightCm / 100;
  if (heightCm >= 110 && heightCm < 130) {
    idealWeight = (heightInMeters - 1) * 40.3 + 14.4;
  } else if (heightCm >= 130 && heightCm <= 250) {
    idealWeight = (heightInMeters - 1.524) * 91 + 48;
  } else {
    throw new Error('Height is outside of the accepted range');
  }
  return roundOutput ? round(idealWeight, 1) : idealWeight;
};

/**
 *
 * @param {*} feet
 * @param {*} inches
 * @returns Ideal body weight in kg to one decimal point, calculated from height in feet and inches
 */
export const idealBodyWeightFromFeet = (feet, inches) => {
  const heightInCm = convertToCm(parseInt(feet, 10), parseInt(inches, 10));
  return idealBodyWeightFromCm(heightInCm);
};

/**
 * Function to round decimals to a specified decimal place
 * @param {*} value - the value to round
 * @param {*} precision - the number of decimal places
 * @returns
 */
export const round = (value, precision) => _.round(value, precision);

/**
 * Function to convert feet/inches to centimeters
 * Height conversion is 2 inch = 5cm with 5cm being the step size
 * @param {*} feet
 * @param {*} inches
 * @returns height in cm
 */
export const convertToCm = (feet, inches) => {
  const totalInches = (parseInt(feet, 10) * 12) + parseInt(inches, 10);
  const totalCm = round(totalInches * INCH_TO_CM_MULTIPLIER, 1);
  return Math.round(totalCm / 5) * 5; // convert to be divisible by step size
};

/**
 * Function to convert height in cm to feet and inches
 * Height conversion is 2 inch = 5cm with 5 cm being the step size
 * @param {*} heightInCm
 * @returns Object { feet: x, inches: y}
 */
export const convertToFeetInches = (heightInCm) => {
  const totalInches = Math.round(parseInt(heightInCm, 10) / INCH_TO_CM_MULTIPLIER);
  const feet = Math.floor(totalInches / 12);
  const inches = totalInches % 12;
  return {
    feet,
    inches,
  };
};

// -------------------------- Vt Provided ------------------------------
/**
 * Calculate the target Va from supplied target tidal volume - vt(ml)
 * @param {*} heightInCm
 * @param {*} targetPatientRate
 * @param {*} tidalVolume - Vt in ml
 * @returns Target Va (L/min)
 *
 * This function uses the equation:
 * Va = ((Vt - deadspace) * TPR) / 1000
 */
export const calculateTargetVaFromVt = (heightInCm, targetPatientRate, tidalVolume) => {
  const deadspaceValue = deadspace(parseInt(heightInCm, 10), false);
  const va = (tidalVolume - deadspaceValue) * targetPatientRate;
  return round((va / 1000), 1);
};

/**
 * Calculates Vt/kg from provided Tidal Volume (Vt)
 * @param {*} vt
 * @param {*} heightInCm
 * @returns Vt/kg (ml/kg)
 *
 * This function uses the equation:
 * Vt/kg = Vt / IdealBodyWeight
 */
export const calculateVtkgFromVt = (vt, heightInCm) => {
  const idealBW = idealBodyWeightFromCm(heightInCm, false);
  return round(vt / idealBW, 1);
};

/**
 * Calculates MV (Ve) from provided Tidal Volume (Vt) and Target Patient Rate
 * @param {*} vt
 * @param {*} heightInCm
 * @returns MV (L/min)
 *
 * This function uses the equation:
 * MV = (vt * TPR) / 1000
 */
export const calculateMVFromVt = (vt, targetPatientRate) => round((vt * targetPatientRate) / 1000, 1);

// -------------------------- Vt/kg Provided ------------------------------
/**
 * Calculate the target Va from supplied vt/kg (ml/kg)
 * @param {*} heightInCm
 * @param {*} targetPatientRate
 * @param {*} vtkg - Vt/kg in (ml/kg)
 * @returns Target Va (L/min)
 *
 * This function uses the equation:
 * Va = (TPR * ((vtkg * IdealBodyWeight) - deadspace)) / 1000
 */
export const calculateTargetVaFromVtkg = (heightInCm, targetPatientRate, vtkg) => {
  const deadspaceValue = deadspace(heightInCm, false);
  const idealWeight = idealBodyWeightFromCm(heightInCm, false);
  const va = ((vtkg * idealWeight) - deadspaceValue) * targetPatientRate;
  return round((va / 1000), 1);
};

/**
 * Calculates Vt from vt/kg
 * @param {*} vtkg
 * @param {*} height
 * @returns Vt (ml)
 * This function uses the equation:
 * Vt = Vt/kg * IdealBodyWeight
 */
export const calculateVtFromVtkg = (vtkg, height) => {
  const idealWeight = idealBodyWeightFromCm(height, false);
  return round(vtkg * idealWeight, 1);
};
/**
 * Calculates MV from vt/kg
 * @param {*} vtkg
 * @param {*} height
 * @returns MV (L/min)
 *
 * This function uses the equation:
 * MV = (TPR * Vt/kg * IdealBodyWeight) / 1000
 */
export const calculateMVFromVtkg = (vtkg, targetPatientRate, height) => {
  const idealWeight = idealBodyWeightFromCm(height, false);
  const vt = vtkg * idealWeight;
  return round((vt * targetPatientRate) / 1000, 1);
};

// -------------------------- MV(Ve) Provided ------------------------------

/**
 * Calculate the target Va from supplied MV (L/min)
 * @param {*} heightInCm
 * @param {*} targetPatientRate
 * @param {*} mv - MV (L/min)
 * @returns Target Va (L/min)
 *
 * This function uses the equation:
 * Va = ((MV * 1000) - (TPR * deadspace)) / 1000
 */
export const calculateTargetVaFromMV = (heightInCm, targetPatientRate, mv) => {
  const deadspaceValue = deadspace(heightInCm, false);
  const va = (mv * 1000) - (targetPatientRate * deadspaceValue);
  return round(va / 1000, 1);
};

/**
 * Calculates Vt(ml) from supplied MV (L/min)
 * @param {*} mv
 * @param {*} targetPatientRate
 * @returns Vt (ml)
 *
 * This function uses the equation:
 * Vt = (MV * 1000) / TPR
 */
export const calculateVtFromMV = (mv, targetPatientRate) => round(((mv * 1000) / targetPatientRate), 1);

/**
 * Calculates vt/kg (ml/kg) from supplied MV (L/min)
 * @param {*} mv
 * @param {*} targetPatientRate
 * @param {*} height
 * @returns vt/kg (ml/kg)
 *
 * This function uses the equation:
 * vt/kg = (MV * 1000)/(TPR * IdealBodyWeight)
 */
export const calculateVtkgFromMV = (mv, targetPatientRate, height) => {
  const idealWeight = idealBodyWeightFromCm(height, false);
  const vtkg = (mv * 1000) / (targetPatientRate * idealWeight);
  return round(vtkg, 1);
};
