import { Analytics, Auth } from 'aws-amplify';
import ReactGA from 'react-ga4';

// NOTE these env variables are passed in as part of the terraform buildspec
const configurePinpointAnalytics = () => {
  if (process.env.REACT_APP_ENV !== 'develop') {
    const amplifyConfig = {
      Auth: {
        identityPoolId: process.env.REACT_APP_IDENTITY_POOL_ID,
        region: process.env.REACT_APP_REGION,
      },
    };
    // Initialize Amplify
    Auth.configure(amplifyConfig);

    const analyticsConfig = {
      AWSPinpoint: {
      // Amazon Pinpoint App Client ID
        appId: process.env.REACT_APP_PINPOINT_ID,
        // Amazon service region
        region: process.env.REACT_APP_REGION,
        mandatorySignIn: false,
      },
    };

    Analytics.configure(analyticsConfig);
    return Analytics;
  }
  return null;
};

export const configureGoogleAnalytics = () => {
  if (process.env.REACT_APP_ENV === 'master') {
    ReactGA.initialize(process.env.REACT_APP_GOOGLE_ANALYTICS_ID);
    return ReactGA;
  }
  return null;
};

const PINPOINT_LOGGER = configurePinpointAnalytics();
const GA_LOGGER = configureGoogleAnalytics();

const logGAEvent = (eventName) => {
  if (GA_LOGGER != null) {
    GA_LOGGER.event(eventName, {
      category: 'User Events',
      action: eventName,
    });
  }
};

const logPinpointEvent = (eventName) => {
  if (PINPOINT_LOGGER != null) {
    PINPOINT_LOGGER.record({
      name: eventName,
    });
  }
};

export const logEvent = (eventName) => {
  logGAEvent(eventName);
  logPinpointEvent(eventName);
};
