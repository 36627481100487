import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import withStyles from '@material-ui/core/styles/withStyles';
import Divider from '@material-ui/core/Divider';
import Markdown from 'markdown-to-jsx';
import PopupDialog from '../PopupDialog/PopupDialog';
import { ReactComponent as ExpandIcon } from '../../icons/ExpandIcon.svg';
import { FAQ, HELP_DIALOG_TITLE, APP_NAME } from '../../utils/strings';
import styles from './HelpDialog.module.scss';

const CustomAccordionSummary = withStyles({
  expandIcon: {
    order: -1,
    marginRight: '5px',
    padding: 0,
  },
})(AccordionSummary);

const CustomPopupDialog = withStyles({
  padding: 0,
})(PopupDialog);

const HelpDialog = ({ isHelpDialogOpen, handleCloseHelpDialog }) => {
  const [expanded, setExpanded] = useState(false);
  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
  return (
    <CustomPopupDialog
      isDialogOpen={isHelpDialogOpen}
      handleCloseDialog={handleCloseHelpDialog}
      className={styles.Container}
    >
      <div className={styles.Header}>{APP_NAME}</div>
      <div className={styles.SubHeader}>{HELP_DIALOG_TITLE}</div>
      <div className={styles.Container}>
        {FAQ.map((data, index) => (
          <Accordion
            className={styles.Accordion}
            expanded={expanded === data.question}
            onChange={handleChange(data.question)}
            key={data.question}
          >
            <CustomAccordionSummary
              expandIcon={<ExpandIcon className={styles.ExpandIcon} />}
              className={styles.Question}
            >{data.question}
              {index < FAQ.length - 1 ? <Divider light /> : null}
            </CustomAccordionSummary>
            <AccordionDetails
              className={styles.Answer}
              id={index}
            >
              <Markdown className={styles.MarkDown}>
                {data.answer}
              </Markdown>
            </AccordionDetails>

          </Accordion>

        ))}
      </div>
    </CustomPopupDialog>
  );
};

HelpDialog.propTypes = {
  isHelpDialogOpen: PropTypes.bool.isRequired,
  handleCloseHelpDialog: PropTypes.func.isRequired,
};

export default HelpDialog;
