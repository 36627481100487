import { React, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import { IconButton } from '@material-ui/core';
import Popover from '../Popover/Popover';
import styles from './InfoButton.module.scss';
import PopupDialog from '../PopupDialog/PopupDialog';
import { ReactComponent as HelpIcon } from '../../icons/HelpIcon.svg';

const InfoButton = ({
  id, helpText, className, children,
}) => {
  const [isDialogOpen, openDialog] = useState(false);
  const handleCloseDialog = () => {
    openDialog(false);
  };
  const handleOpenDialog = () => {
    openDialog(true);
  };

  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    handleOpenDialog();
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const open = Boolean(anchorEl);
  const anchor = open ? 'metric-details-popover' : undefined;

  useEffect(() => {
    const handleScroll = () => {
      handleClose();
    };
    window.addEventListener('scroll', handleScroll, { passive: true });
    return () => window.removeEventListener('scroll', handleScroll);
  });

  return (
    <div className={className || ''}>
      <IconButton className={styles.ButtonContainer} onClick={handleClick}>
        <HelpIcon
          id={id}
          aria-describedby={id}
          className={styles.HelpButton}
          color="secondary"
        />
      </IconButton>
      {children ? (
        <PopupDialog
          isDialogOpen={isDialogOpen}
          handleCloseDialog={handleCloseDialog}
        >{children}
        </PopupDialog>
      ) : (
        <Popover
          id={anchor}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
        >
          <Typography className={styles.HelpText}>{helpText}</Typography>
        </Popover>
      )}

    </div>
  );
};

InfoButton.propTypes = {
  id: PropTypes.string.isRequired,
  helpText: PropTypes.string,
  className: PropTypes.string,
  children: PropTypes.node,
};
export default InfoButton;
