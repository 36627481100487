import React from 'react';
import PropTypes from 'prop-types';
import {
  ButtonGroup, Button, Slider,
} from '@material-ui/core';
import styles from './HeightCard.module.scss';
import Paper from '../Paper/Paper';
import InfoButton from '../InfoButton/InfoButton';
import {
  FIELD_KEYS, UNITS as Units,
} from '../../utils/constants';
import {
  HEIGHT_FT_OPTION_TITLE,
} from '../../utils/strings';
import { FeetInchesSelector } from './FeetInchesSelector';

const HeightCard = ({
  heightCm, heightFt, heightIn, title, metricDetails, errorMessage,
  icon, selectedHeightUnit, onToggleHeightUnit, onHeightChange,
}) => (
  <Paper className={errorMessage && selectedHeightUnit === Units.FT
    ? [styles.Card, styles.error].join(' ') : styles.Card}
  >
    <div className={styles.titleContainer}>
      <div className={styles.Header}>{title}</div>
      <ButtonGroup
        elevation={0}
        orientation="horizontal"
        variant="outlined"
        aria-label="outlined primary button group"
        className={styles.ButtonGroup}
      >
        <Button
          key={Units.CM}
          elevation={0}
          className={selectedHeightUnit === Units.FT ? styles.UnitButton : [styles.UnitButton, styles.selected].join(' ')}
          onClick={() => onToggleHeightUnit(Units.CM)}
        >
          {Units.CM}
        </Button>
        <Button
          key={Units.FT}
          className={selectedHeightUnit === Units.FT ? [styles.UnitButton, styles.selected].join(' ') : styles.UnitButton}
          onClick={() => onToggleHeightUnit(Units.FT)}
        >
          {HEIGHT_FT_OPTION_TITLE}
        </Button>
      </ButtonGroup>
    </div>
    {!(selectedHeightUnit === Units.FT)
        && (
          <div className={styles.SliderContainer}>
            <Slider
              id={FIELD_KEYS.HEIGHT_CM}
              name={FIELD_KEYS.HEIGHT_CM}
              value={heightCm}
              aria-labelledby="height-in-cm-slider"
              step={5}
              marks
              min={135}
              max={250}
              valueLabelDisplay="on"
              onChange={(event, value) => onHeightChange(value, FIELD_KEYS.HEIGHT_CM)}
              classes={{ root: styles.Slider }}
            />
          </div>
        )}
    {selectedHeightUnit === Units.FT && (
      <>
        <FeetInchesSelector
          onHeightChange={onHeightChange}
          errorMessage={errorMessage}
          ftValue={heightFt}
          inValue={heightIn}
        />
      </>
    )}
    <div className={styles.Container}>
      <InfoButton
        id={`popover-${title}`}
        helpText={metricDetails}
      />
      {icon}
    </div>
  </Paper>
);
HeightCard.propTypes = {
  title: PropTypes.string.isRequired,
  heightCm: PropTypes.number.isRequired,
  heightFt: PropTypes.number.isRequired,
  heightIn: PropTypes.number.isRequired,
  metricDetails: PropTypes.string.isRequired,
  errorMessage: PropTypes.string,
  icon: PropTypes.node,
  selectedHeightUnit: PropTypes.string.isRequired,
  onToggleHeightUnit: PropTypes.func,
  onHeightChange: PropTypes.func,
};

export default HeightCard;
