import React from 'react';
import PropTypes from 'prop-types';
import {
  TextField, FormHelperText, FormControl,
} from '@material-ui/core';
import styles from './MetricCard.module.scss';
import Paper from '../Paper/Paper';
import InfoButton from '../InfoButton/InfoButton';
import { ReactComponent as WarningIcon } from '../../icons/WarningIcon.svg';

const MetricCard = ({
  id, value, name, unit, placeholder, metricDetails, metricDialogDetails, error,
  icon, handleInputChange, allowDecimals, warning,
}) => {
  const stepVal = allowDecimals ? 0.1 : 1;
  const regexForDecimals = /^\d*(\.\d{1})?$/gm; // only allow digits with one decimal place
  const regexForIntegers = /^\d*$/gm;

  const onChange = (event) => {
    const input = event.target;
    if ((allowDecimals && regexForDecimals.test(input.value)) || regexForIntegers.test(input.value)) {
      handleInputChange(input.value, input.id);
    }
  };

  return (
    <Paper className={[styles.Card, error ? styles.error : '', warning ? styles.warning : '']
      .join(' ')}
    >
      <div className={styles.titleContainer}>
        <div className={styles.Header}>{name}</div>
      </div>
      <div className={styles.valueContainer}>
        <FormControl error>
          <TextField
            inputProps={{
              step: stepVal,
              inputMode: 'decimal',
            }}
            // eslint-disable-next-line react/jsx-no-duplicate-props
            InputProps={{
              classes: { root: styles.TextField },
            }}
            id={id}
            value={value}
            variant="outlined"
            type="number"
            pattern="^(\d*\.)?\d+$"
            placeholder={placeholder.toString()}
            onChange={onChange}
            onBlur={onChange}
            autoComplete="none"
            color="primary"
            onFocus={(event) => {
              event.target.select();
            }}
            onMouseUp={(e) => e.preventDefault()}
          />
        </FormControl>
        <h2 className={styles.Unit}>{unit}</h2>
      </div>
      {error && <FormHelperText id="component-error-text" className={styles.ErrorText}>{error}</FormHelperText>}
      {(warning && !error)
      && (
      <FormHelperText id="component-error-text" className={styles.WarningText}><WarningIcon className={styles.WarningIcon} />
        {warning}
      </FormHelperText>
      )}
      <div className={styles.Container}>
        <InfoButton
          id={`popover-${name}`}
          helpText={metricDetails}
        >{metricDialogDetails}
        </InfoButton>
        <div className={styles.Icon}>
          {icon}
        </div>
      </div>
    </Paper>
  );
};

MetricCard.propTypes = {
  handleInputChange: PropTypes.func.isRequired,
  id: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  unit: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.number,
  metricDetails: PropTypes.string.isRequired,
  metricDialogDetails: PropTypes.node,
  error: PropTypes.string,
  warning: PropTypes.string,
  icon: PropTypes.node,
  allowDecimals: PropTypes.bool,

};

export default MetricCard;
