/* eslint-disable max-len */
export const APP_NAME = 'iVAPS Calculator';
export const OTHER_METRICS_TITLE = 'Other metrics';
export const ERROR_MESSAGE = 'Enter a value';
// App Update
export const UPDATE_READY_MESSAGE = 'A new version is available';
export const RELOAD_MESSAGE = 'Update now';

// IE warning
export const IE_WARNING_MESSAGE = 'You appear to be using an unsupported browser. For optimal performance please use either Chrome, Firefox, Edge or Safari';
// Metric names
export const HEIGHT_TITLE = 'Height';
export const HEIGHT_FT_OPTION_TITLE = 'ft & in';
export const PATIENT_RATE_TITLE = 'Target patient rate';
export const DEADSPACE_TITLE = 'Anatomical deadspace';
export const IDEAL_BODY_WEIGHT_TITLE = 'Ideal body weight';
export const VTKG_TITLE = 'Vt/kg';
export const MV_TITLE = 'Minute ventilation';
export const VT_TITLE = 'Tidal volume';
// Metric help text
export const HEIGHT_HELP = 'The height of the patient in centimeters (cm) or feet and inches (’ ”). Height in cm is measured in 5 cm or 2” increments.';
export const PATIENT_RATE_HELP = 'Target patient rate is the respiratory rate (RR) or the number of breaths in one minute.';
export const VTKG_HELP = 'Tidal volume (Vt) is the volume of air inspired in a single breath.';
export const VT_HELP = VTKG_HELP;
export const MV_HELP = 'Minute ventilation (MV or Ve) is the volume of air inspired in one minute. It is equal to tidal volume multiplied by respiratory rate.';
export const DEADSPACE_HELP = 'Anatomical deadspace is the volume of the upper airway that does not participate in gas exchange. It is estimated from patient height. It is calculated using the following formula: ';
export const DEADSPACE_HELP_2 = 'The formula is derived from published data: “Hart MC et al. Relation between anatomic respiratory dead space and body size and lung volume."';

// Validation Messages
export const HEIGHT_RANGE_ERROR = 'Enter a value that is between 3’8” and 8’4”.';
export const EMPTY_ERROR = 'Required';
export const PATIENT_RATE_RANGE_ERROR = 'The target patient rate must be between 8bpm and 30bpm';
export const IDEAL_BODY_WEIGHT_HELP = 'Ideal body weight (IBW) is determined by patient height and can be derived from a common medical statistics table. The calculator uses an average of male and female IBW formulas from ARDSnet.';
export const PREFERRED_TARGET = 'Preferred input';
export const TARGET_VA_WARNING = 'Valid Va values are within a range of 1 to 30 L/min.';
export const METRIC_CARD_WARNING = 'This will result in an invalid Va value outside the range 1 to 30 L/min.';
export const COPY_TO_CLIPBOARD = 'Copy value to the clipboard: ';

// Calculation buttons
export const CALCULATE = 'Calculate';
export const RESET = 'Reset';

// Target Va Title
export const TARGETVA = 'Alveolar Ventilation (Va)';

// Legal Notice Dialog
export const RESMED = 'ResMed';
export const LEGAL_NOTICE_TITLE = 'iVAPS Settings Terms and Conditions';
export const AGREE = 'I accept the above T&C';
export const HIDE_NOTICE = 'Do not show me this again.';
export const LEGAL = 'Legal';
export const VERSION = 'Version';
export const HELP = 'Help';

// eslint-disable max-len
// eslint-disable-next-line no-undef
// Legal notice
export const LEGAL_NOTICE_CONTENT = `These terms govern your use of ResMed’s iVAPS Va calculator. Please read the terms carefully. To confirm your understanding and acceptance of the terms, click “I Accept the above T&C.” If you do not agree to these terms, do not click “I Accept the above T&C” and do not use ResMed’s iVAPS Va calculator
  iVAPS (Intelligent volume assured pressure support) is a recent mode of non-invasive ventilation developed by ResMed Ltd.
  The iVAPs Va calculator includes clinical tools and content intended solely for use by trained healthcare professionals who are experienced in the calculation of alveolar target volume.',
  These tools do not give professional advice; physicians and other healthcare professionals who use the iVAPS Va calculator should exercise their own clinical judgment as to the information they provide. Consumers (non-medical professionals) who use the tools or databases do so at their own risk. Individuals with any type of medical condition are specifically cautioned to seek professional medical advice before beginning any sort of health treatment. For medical concerns, including decisions about sleep disorder treatments, non-medical users should always consult their physician or other qualified healthcare professional.
  While information on the iVAPS Va calculator has been obtained from sources believed to be reliable, neither ResMed nor any of its subsidiaries or affiliates (together, ResMed) nor any content provider warrants the accuracy of the information contained on the iVAPS Va calculator.
  ResMed does not give medical advice, nor does the iVAPS Va calculator provide medical or diagnostic services.
  Your reliance upon information and content obtained by you through this iVAPS Va calculator is solely at your own risk. Neither ResMed nor its content providers assume any liability or responsibility for damage or injury (including death) to you, other persons, or property arising from any use of any product, information, idea, or instruction contained in the content or services provided to you.`;

// Help Dialog
export const HELP_DIALOG_TITLE = 'Frequently Asked Questions';

export const FAQ = [
  { question: 'How do I download or install the app on my phone?', answer: 'On Android phones, tap the **More Options** icon in the right-hand corner and **tap Install app > Install**. <br/>On iOS phones, tap the **Action** icon below and tap **Add to Home screen > Add**.' },
  { question: 'Can I install the app on my PC/Mac?', answer: 'Yes. In Chrome, in the address bar (top of screen, far right), click the **Install iVAPS Calculator** icon and **Install**. You can now access the iVAPS Calculator on your **Desktop**.' },
  { question: 'How can I enter my patient’s exact height?', answer: 'The app calculates your patient’s approximate height. Enter a height value to the nearest 5 cm or 2” increment.' },
  { question: 'The Calculate button is grayed out. What should I do?', answer: 'Check for error messages. If you do not see any error messages, click/tap **Reset** to clear the values and start over.' },
  { question: 'Why is the Va value from the app not exactly the same as the Va value that displays on my ResMed machine?', answer: 'The app’s calculated Va value is based on an optimized formula, found in the clinical guide of the ResMed machine, and may differ slightly from the calculated value on some other ResMed machines.' },
  { question: 'Can I use this app to directly determine my patient’s prescription?', answer: 'The iVAPS Calculator is not intended to provide medical advice or to be used as a diagnostic; healthcare professionals who use the iVAPS Calculator should exercise their own clinical judgment.' },
  { question: 'Is there a detailed step-by-step guide on how to use the app?', answer: 'Yes. You can view and download the iVAPS Calculator User Guide <a href="https://media.resmed.com/sites/6/20211207200201/iVAPSCalculatorUserGuideEng.pdf" target="_blank">here</a>.' },
];
