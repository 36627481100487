import React, { useEffect, useState } from 'react';
import { Snackbar, Button } from '@material-ui/core';
import { createMuiTheme } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/styles';
import LegalNoticeDialog from './components/LegalNoticeDialog/LegalNoticeDialog';
import Calculator from './components/Calculator/Calculator';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import * as strings from './utils/strings';
import WordMark from './icons/iVAPSWordmark.svg';
import ResmedLogo from './icons/ResmedLogo.jpg';
import HelpDialog from './components/HelpDialog/HelpDialog';
import styles from './App.module.scss';
import { logEvent } from './utils/analytics';

export const isIE = /* @cc_on!@ */false || !!document.documentMode;

const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#0077C0',
    },
    secondary: {
      main: 'rgb(247,241,255)',
    },
  },
});

function App() {
  const [isOpenDialog, setOpenDialog] = useState(true);
  const [isdialogReadOnly, setDialogReadOnly] = useState(false);
  const [checked, setChecked] = useState(false);
  const [showReload, setShowReload] = useState(false);
  const [waitingWorker, setWaitingWorker] = useState(null);
  const [isHelpDialogOpen, openHelpDialog] = useState(false);
  const [showIEWarning, setShowIEWarning] = useState(isIE);

  const handleCloseHelpDialog = () => {
    openHelpDialog(false);
  };

  const handleOpenHelpDialog = () => {
    logEvent('Help Dialog Closed');
    openHelpDialog(true);
  };

  const IS_CHECKED = 'isChecked';

  const onSWUpdate = (registration) => {
    setShowReload(true);
    setWaitingWorker(registration.waiting);
  };

  const reloadPage = () => {
    waitingWorker?.postMessage({ type: 'SKIP_WAITING' });
    setShowReload(false);
    window.location.reload();
  };

  const closeDialog = () => {
    logEvent('Legal Dialog Closed');
    setOpenDialog(false);
  };

  const openReadOnlyDialog = () => {
    setDialogReadOnly(true);
    setOpenDialog(true);
  };

  const handleCheck = () => {
    const updateChecked = !checked;
    setChecked(updateChecked);
    localStorage.setItem(IS_CHECKED, JSON.stringify(updateChecked));
  };

  useEffect(() => {
    const loadedCheckboxState = localStorage.getItem(IS_CHECKED);
    if (loadedCheckboxState !== null) {
      const parsedValues = JSON.parse(loadedCheckboxState);
      setChecked(parsedValues);
      setOpenDialog(!parsedValues);
    }
    serviceWorkerRegistration.register({ onUpdate: onSWUpdate });
    logEvent('Application Loaded');
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <div className={styles.TitleContainer}>
        <img className={styles.WordMark} src={WordMark} alt="" />
        <div className={styles.ButtonContainer}>
          <Button className={styles.Help} onClick={handleOpenHelpDialog} color="primary" variant="text">
            {strings.HELP}
          </Button>
          <Button className={styles.Legal} onClick={openReadOnlyDialog} color="primary" variant="text">
            {strings.LEGAL}
          </Button>
        </div>
      </div>
      <div className={styles.App}>
        <LegalNoticeDialog
          isOpenDialog={isOpenDialog}
          closeDialog={closeDialog}
          handleCheck={handleCheck}
          checked={checked}
          readOnly={isdialogReadOnly}
        />
        <HelpDialog
          isHelpDialogOpen={isHelpDialogOpen}
          handleCloseHelpDialog={handleCloseHelpDialog}
        />
        <Calculator />
        <Snackbar
          open={showReload}
          message={strings.UPDATE_READY_MESSAGE}
          onClick={reloadPage}
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
          action={(
            <Button
              color="inherit"
              size="small"
              onClick={reloadPage}
              classes={{ label: styles.SnackbarButtonLabel }}
            >
              {strings.RELOAD_MESSAGE}
            </Button>
          )}
        />
        <Snackbar
          open={showIEWarning}
          message={strings.IE_WARNING_MESSAGE}
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
          action={(
            <Button
              color="inherit"
              size="small"
              onClick={() => setShowIEWarning(false)}
              classes={{ label: styles.SnackbarButtonLabel }}
            >
              x
            </Button>
          )}
        />
        <img className={styles.Logo} src={ResmedLogo} alt="" />
        <p className={styles.Version}>{strings.VERSION}: {process.env.REACT_APP_VERSION}</p>

      </div>

    </ThemeProvider>
  );
}

export default App;
