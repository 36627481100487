import React, { useEffect, useState } from 'react';
import {
  Paper, Button,
} from '@material-ui/core';
import AwesomeDebouncePromise from 'awesome-debounce-promise';
import styles from './Calculator.module.scss';
import MetricCard from '../MetricCard/MetricCard';
import * as strings from '../../utils/strings';
import MetricListCard from '../MetricListCard/MetricListCard';
import TargetSelector from '../TargetSelector/TargetSelector';
import HeightCard from '../HeightCard/HeightCard';
import PopupDialog from '../PopupDialog/PopupDialog';
import {
  UNITS, INITIAL_DATA, FIELD_KEYS, METRIC_DATA, INPUT_TARGET as TARGET_OPTIONS,
} from '../../utils/constants';
import {
  validate, getOtherMetricsList, getAllMetricsList, getHeightErrorMessage, calculateAssociatedValues, calculateTargetVa,
  resetTargetValues,
} from '../../utils/RenderHelpers';
import { logEvent } from '../../utils/analytics';

const Calculator = () => {
  const ENTERED_VALUES = 'enteredValues';
  const [enteredValues, setEnteredValues] = useState(INITIAL_DATA);
  const [showAnswerDialog, setShowAnswerDialog] = useState(false);
  const [errors, setErrors] = useState({});
  const [warning, setWarning] = useState();
  const [targetVa, setTargetVa] = useState();

  useEffect(() => {
    const loadedInitialValues = localStorage.getItem(ENTERED_VALUES);
    if (loadedInitialValues !== null) {
      const parsedValues = JSON.parse(loadedInitialValues);
      setEnteredValues(parsedValues);
    }
  }, []);

  const onCalculatePressed = (event) => {
    event.preventDefault();
    setShowAnswerDialog(true);
    setTargetVa(calculateTargetVa(enteredValues));
    logEvent('Calculate Button Pressed');
  };

  const debouncedCalculateTargetVa = AwesomeDebouncePromise((updatedObject) => calculateTargetVa(updatedObject), 400);

  useEffect(() => {
    let isMounted = true;
    debouncedCalculateTargetVa(enteredValues).then((va) => {
      if (!isMounted) return;
      if (va > 30 || va < 1) {
        setWarning(strings.METRIC_CARD_WARNING);
      } else {
        setWarning();
      }
    });
    return () => { isMounted = false; };
  }, [enteredValues]);

  const resetForm = () => {
    const { selectedTarget, selectedHeightUnit } = enteredValues;
    setErrors({});
    const resetObj = {
      ...INITIAL_DATA,
      [FIELD_KEYS.SELECTED_TARGET]: selectedTarget,
      [FIELD_KEYS.SELECTED_HEIGHT_UNIT]: selectedHeightUnit,
    };
    setEnteredValues(resetObj);
    localStorage.setItem(ENTERED_VALUES, JSON.stringify(resetObj));
    setWarning();
    logEvent('Reset Form Pressed');
  };

  const formIsValid = (errorObj) => Object.values(errorObj).every((x) => x === null || x === '');

  const updateAndSaveInput = (updatedObject) => {
    setEnteredValues(updatedObject);
    localStorage.setItem(ENTERED_VALUES, JSON.stringify(updatedObject));
  };

  const recalculateAndSave = (stateKey, value, formValid, updatedObject) => {
    const currentState = updatedObject || enteredValues;
    if (formValid) {
      const updatedStateObject = calculateAssociatedValues(currentState, stateKey, value);
      setEnteredValues(updatedStateObject);
      localStorage.setItem(ENTERED_VALUES, JSON.stringify(updatedStateObject));
    }
  };

  const handleInputChange = (value, id) => {
    let convertedValue = value;
    if (value && !Number.isNaN(value)) {
      if (Number.isInteger(value)) {
        convertedValue = parseInt(value, 10);
      } else {
        convertedValue = parseFloat(value);
      }
    }
    const error = validate(enteredValues, id, convertedValue);
    const updatedErrors = { ...errors, [error.key]: error.message };
    setErrors(updatedErrors);
    const updatedObject = { ...enteredValues, [id]: convertedValue };
    updateAndSaveInput(updatedObject);
    if (value) { // don't re-calculate if a value is empty
      recalculateAndSave(id, convertedValue, formIsValid(updatedErrors));
    }
  };

  const onPreferredTargetChange = (event) => {
    const input = event.target;
    const { value } = input;
    // reset targets to defaults and clear target related errors
    const updatedState = resetTargetValues(value, enteredValues, errors);
    updateAndSaveInput(updatedState.updatedObject);
    setErrors(updatedState.updatedErrors);
    recalculateAndSave(FIELD_KEYS.SELECTED_TARGET, value, formIsValid(updatedState.updatedErrors),
      updatedState.updatedObject);
  };

  const onToggleHeightUnit = (id) => {
    const updatedObject = {
      ...enteredValues,
      [FIELD_KEYS.SELECTED_HEIGHT_UNIT]: id,
    };
    updateAndSaveInput(updatedObject);
    recalculateAndSave(FIELD_KEYS.SELECTED_HEIGHT_UNIT, id, formIsValid(errors),
      updatedObject);
  };

  const handleCloseAnswerDialog = () => {
    setShowAnswerDialog(false);
  };

  const {
    selectedTarget, mv, vtkg, vt, heightCm, patientRate, selectedHeightUnit, heightFt, heightIn,
  } = enteredValues;
  return (
    <div className={styles.Outside}>
      <Paper className={styles.Paper}>
        <form onSubmit={onCalculatePressed}>
          <div className={styles.FlexContainer}>
            <HeightCard
              heightCm={heightCm}
              heightFt={heightFt}
              heightIn={heightIn}
              title={METRIC_DATA.heightCm.name}
              metricDetails={METRIC_DATA.heightCm.helpText}
              errorMessage={getHeightErrorMessage(errors)}
              icon={METRIC_DATA.heightCm.icon}
              selectedHeightUnit={selectedHeightUnit}
              onToggleHeightUnit={onToggleHeightUnit}
              onHeightChange={handleInputChange}
            />
            <MetricCard
              id={FIELD_KEYS.PATIENT_RATE}
              value={patientRate}
              name={METRIC_DATA.patientRate.name}
              unit={UNITS.BPM}
              placeholder={METRIC_DATA.patientRate.default}
              metricDetails={METRIC_DATA.patientRate.helpText}
              error={errors[FIELD_KEYS.PATIENT_RATE]}
              icon={METRIC_DATA.patientRate.icon}
              handleInputChange={handleInputChange}
              min={8}
              max={30}
              allowDecimals={false}
            />
            <TargetSelector
              title={strings.PREFERRED_TARGET}
              options={Object.values(TARGET_OPTIONS)}
              value={selectedTarget}
              handleChange={onPreferredTargetChange}
            />
            {selectedTarget === TARGET_OPTIONS.mv.name ? (
              <MetricCard
                id={FIELD_KEYS.MV}
                value={mv}
                name={METRIC_DATA.mv.name}
                unit={UNITS.LMIN}
                placeholder={METRIC_DATA.mv.default}
                metricDetails={METRIC_DATA.mv.helpText}
                error={errors[FIELD_KEYS.MV]}
                icon={METRIC_DATA.mv.icon}
                handleInputChange={handleInputChange}
                warning={warning}
                allowDecimals
              />
            ) : null}
            {selectedTarget === TARGET_OPTIONS.vtkg.name ? (
              <MetricCard
                id={FIELD_KEYS.VTKG}
                value={vtkg}
                name={METRIC_DATA.vtkg.name}
                unit={UNITS.MLKG}
                placeholder={METRIC_DATA.vtkg.default}
                metricDetails={METRIC_DATA.vtkg.helpText}
                error={errors[FIELD_KEYS.VTKG]}
                icon={METRIC_DATA.vtkg.icon}
                handleInputChange={handleInputChange}
                warning={warning}
                allowDecimals
              />
            ) : null}
            {selectedTarget === TARGET_OPTIONS.vt.name ? (
              <MetricCard
                id={FIELD_KEYS.VT}
                value={vt}
                name={METRIC_DATA.vt.name}
                unit={UNITS.ML}
                placeholder={METRIC_DATA.vt.default}
                metricDetails={METRIC_DATA.vt.helpText}
                error={errors[FIELD_KEYS.VT]}
                icon={METRIC_DATA.vt.icon}
                handleInputChange={handleInputChange}
                warning={warning}
              />
            ) : null}

          </div>
          <MetricListCard
            title={strings.OTHER_METRICS_TITLE}
            metricList={getOtherMetricsList(selectedTarget, enteredValues)}
            showHelpIcons
            handleCloseAnswerDialog={handleCloseAnswerDialog}
          />
          <div className={styles.CalculateButtonGroup}>
            <Button
              className={styles.ResetButton}
              onClick={() => resetForm()}
            >
              {strings.RESET}
            </Button>
            <Button
              color="primary"
              variant="contained"
              type="submit"
              className={styles.CalculateButton}
              disabled={!formIsValid(errors)}
            >
              {strings.CALCULATE}
            </Button>
          </div>

        </form>
        <PopupDialog
          isDialogOpen={showAnswerDialog}
          handleCloseDialog={handleCloseAnswerDialog}
        ><MetricListCard
          targetVa={targetVa}
          vaUnit={UNITS.LMIN}
          metricList={getAllMetricsList(selectedTarget, enteredValues)}
          title={strings.TARGETVA}
        />
        </PopupDialog>
      </Paper>
    </div>
  );
};

export default Calculator;
