import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  DialogContent, Button, Checkbox,
} from '@material-ui/core';
import Paper from '../Paper/Paper';
import styles from './LegalNoticeDialog.module.scss';
import * as strings from '../../utils/strings';
import PopupDialog from '../PopupDialog/PopupDialog';

const LegalNoticeDialog = ({
  isOpenDialog, closeDialog, handleCheck, checked, readOnly,
}) => {
  useEffect(() => {
    if (isOpenDialog) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'auto';
    }
  }, [isOpenDialog]);

  return (
    <PopupDialog
      handleCloseDialog={() => readOnly && closeDialog()}
      isDialogOpen={isOpenDialog}
      disableScrollLock
      PaperProps={{ classes: { root: styles.DialogBackground } }}
      preventClose={!readOnly}
    >
      <Paper className={styles.Paper}>
        <DialogContent className={styles.DialogContent}>
          <div className={styles.Header}>{strings.RESMED}</div>

          <div className={styles.SubHeader}>{strings.LEGAL_NOTICE_TITLE}</div>
          {strings.LEGAL_NOTICE_CONTENT.split('\n').map((txt, index) => <p key={`paragraph ${index + 1}`}>{txt}</p>)}
        </DialogContent>
        {!readOnly && (
          <>
            <div className={styles.CheckboxLabel}>
              <Checkbox
                checked={checked}
                color="primary"
                onChange={handleCheck}
                inputProps={{ 'aria-label': 'primary checkbox' }}
              />
              {strings.HIDE_NOTICE}
            </div>
            <Button onClick={closeDialog} className={styles.AgreeButton} color="primary" variant="outlined">
              {strings.AGREE}
            </Button>
          </>
        )}
      </Paper>
    </PopupDialog>
  );
};

LegalNoticeDialog.propTypes = {
  readOnly: PropTypes.bool.isRequired,
  isOpenDialog: PropTypes.bool.isRequired,
  closeDialog: PropTypes.func.isRequired,
  checked: PropTypes.bool.isRequired,
  handleCheck: PropTypes.func.isRequired,
};

export default LegalNoticeDialog;
