import React from 'react';
import PropTypes from 'prop-types';
import { FormControlLabel, RadioGroup, Radio } from '@material-ui/core';
import styles from './TargetSelector.module.scss';
import Paper from '../Paper/Paper';

const TargetSelector = ({
  title, options, value, handleChange,
}) => (
  <Paper className={styles.MainContainer}>
    <div className={styles.Header}>{title}</div>
    <RadioGroup
      className={styles.RadioGroup}
      aria-label="preferred-target"
      name="preferred-target"
      value={value}
      onChange={handleChange}
    >
      {options.map((element) => (
        <FormControlLabel
          key={element.name}
          value={element.name}
          control={<Radio classes={{ root: styles.Radio, checked: styles.Radio__checked }} />}
          label={`${element.name} (${element.unit})`}
        />
      ))}
    </RadioGroup>
  </Paper>
);

TargetSelector.propTypes = {
  title: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  handleChange: PropTypes.func.isRequired,
  options: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string.isRequired,
    default: PropTypes.number.isRequired,
    unit: PropTypes.string.isRequired,
  })).isRequired,
};
export default TargetSelector;
