import React from 'react';
import * as strings from './strings';
import { convertToFeetInches, deadspace, idealBodyWeightFromCm } from './IVAPSCalculator';
import { ReactComponent as HeightIcon } from '../icons/MetricCardIcons/Height.svg';
import { ReactComponent as MinuteVentilationIcon } from '../icons/MetricCardIcons/MinuteVentilation.svg';
import { ReactComponent as TargetPatientRateIcon } from '../icons/MetricCardIcons/TargetPatientRate.svg';
import { ReactComponent as TidalVolumeIcon } from '../icons/MetricCardIcons/TidalVolume.svg';
import { ReactComponent as VtKgIcon } from '../icons/MetricCardIcons/VtKg.svg';
import { AnatomicalDeadspace } from '../components/PopupDialog/PopupDialogContent';

export const UNITS = {
  FT: '\'',
  IN: '"',
  CM: 'cm',
  ML: 'mL',
  KG: 'kg',
  LMIN: 'L/min',
  MLKG: 'mL/kg',
  BPM: 'bpm',
};

export const INPUT_TARGET = {
  vt: {
    name: 'Tidal volume',
    unit: UNITS.ML,
    default: 548,
  },
  mv: {
    name: 'Minute ventilation',
    unit: UNITS.LMIN,
    default: 8.2,
  },
  vtkg: {
    name: 'Vt/kg',
    unit: UNITS.MLKG,
    default: 8.0,
  },
};

/** These are used for state as well as to identify corresponding form fields  */
export const FIELD_KEYS = {
  SELECTED_TARGET: 'selectedTarget',
  SELECTED_HEIGHT_UNIT: 'selectedHeightUnit',
  HEIGHT_CM: 'heightCm',
  HEIGHT_FT: 'heightFt',
  HEIGHT_IN: 'heightIn',
  PATIENT_RATE: 'patientRate',
  VTKG: 'vtkg',
  VT: 'vt',
  MV: 'mv',
  DEADSPACE: 'deadspace',
  TARGET_VA: 'targetVa',
  IDEAL_BW: 'idealBodyWeight',
};

export const METRIC_DATA = {
  heightCm: {
    key: FIELD_KEYS.HEIGHT_CM,
    name: strings.HEIGHT_TITLE,
    default: 175,
    helpText: strings.HEIGHT_HELP,
    unit: UNITS.CM,
    includeInMetricList: false,
    icon: <HeightIcon />,
  },
  patientRate: {
    key: FIELD_KEYS.PATIENT_RATE,
    name: strings.PATIENT_RATE_TITLE,
    default: 15,
    helpText: strings.PATIENT_RATE_HELP,
    unit: UNITS.BPM,
    includeInMetricList: false,
    icon: <TargetPatientRateIcon />,
  },
  vtkg: {
    key: FIELD_KEYS.VTKG,
    name: INPUT_TARGET.vtkg.name,
    default: INPUT_TARGET.vtkg.default,
    helpText: strings.VTKG_HELP,
    unit: UNITS.MLKG,
    includeInMetricList: true,
    icon: <VtKgIcon />,
  },
  vt: {
    key: FIELD_KEYS.VT,
    name: INPUT_TARGET.vt.name,
    default: INPUT_TARGET.vt.default,
    helpText: strings.VT_HELP,
    unit: UNITS.ML,
    includeInMetricList: true,
    icon: <TidalVolumeIcon />,
  },
  mv: {
    key: FIELD_KEYS.MV,
    name: INPUT_TARGET.mv.name,
    default: INPUT_TARGET.mv.default,
    helpText: strings.MV_HELP,
    unit: UNITS.LMIN,
    includeInMetricList: true,
    icon: <MinuteVentilationIcon />,
  },
  deadspace: {
    key: FIELD_KEYS.DEADSPACE,
    name: strings.DEADSPACE_TITLE,
    default: 120,
    metricDialogDetails: <AnatomicalDeadspace />,
    unit: UNITS.ML,
    includeInMetricList: true,
  },
  idealBodyWeight: {
    key: FIELD_KEYS.IDEAL_BW,
    name: strings.IDEAL_BODY_WEIGHT_TITLE,
    default: 70,
    helpText: strings.IDEAL_BODY_WEIGHT_HELP,
    unit: UNITS.KG,
    includeInMetricList: true,
  },
};

export const INITIAL_DATA = {
  selectedTarget: INPUT_TARGET.vt.name,
  heightCm: METRIC_DATA.heightCm.default,
  heightFt: convertToFeetInches(METRIC_DATA.heightCm.default).feet,
  heightIn: convertToFeetInches(METRIC_DATA.heightCm.default).inches,
  patientRate: METRIC_DATA.patientRate.default,
  vtkg: INPUT_TARGET.vtkg.default,
  vt: INPUT_TARGET.vt.default,
  mv: INPUT_TARGET.mv.default,
  deadspace: deadspace(METRIC_DATA.heightCm.default),
  idealBodyWeight: idealBodyWeightFromCm(METRIC_DATA.heightCm.default),
  selectedHeightUnit: UNITS.CM,
};

export const FT_MENU_OPTIONS = [4, 5, 6, 7, 8];
export const IN_MENU_OPTIONS = [0, 2, 4, 6, 8, 10];
