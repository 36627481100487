import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import {
  Dialog, DialogContent,
} from '@material-ui/core';
import styles from './PopupDialog.module.scss';
import CloseButton from '../CloseButton/CloseButton';

const CustomDialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(DialogContent);

const PopupDialog = ({
  isDialogOpen, handleCloseDialog, preventClose, children,
}) => {
  // This is because of a bug in material UI dialog that doesn't always remove overflow:hidden when dialog is closed
  useEffect(() => {
    if (isDialogOpen) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'auto';
    }
  }, [isDialogOpen]);

  return (
    <Dialog
      onClose={handleCloseDialog}
      open={isDialogOpen}
      disableScrollLock
      PaperProps={{ classes: { root: styles.DialogBackground } }}
    >
      <div className={styles.Paper}>
        {!preventClose && (
        <div className={styles.CloseButtonContainer}>
          <CloseButton handleCloseAnswerDialog={handleCloseDialog} />
        </div>
        )}
        <CustomDialogContent className={styles.DialogContent}>
          {children}
        </CustomDialogContent>
      </div>
    </Dialog>
  );
};

PopupDialog.propTypes = {
  children: PropTypes.node,
  handleCloseDialog: PropTypes.func,
  isDialogOpen: PropTypes.bool,
  preventClose: PropTypes.bool,
};

export default PopupDialog;
