import React from 'react';
import { Typography } from '@material-ui/core';
import * as strings from '../../utils/strings';
import { ReactComponent as AnatomicalDeadSpaceImage } from '../../icons/AnatomicalDeadspace.svg';
import styles from './PopupDialogContent.module.scss';
import AnatomicalDeadspaceFormula from '../../icons/AnatomicalDeadSpaceFormula.png';

export const AnatomicalDeadspace = () => (
  <div className={styles.Container}>
    <Typography className={styles.DialogTitle}>{strings.DEADSPACE_TITLE}</Typography>
    <AnatomicalDeadSpaceImage className={styles.Image} />
    <div className={styles.TextContainer}>{strings.DEADSPACE_HELP}</div>
    <img className={styles.Image} src={AnatomicalDeadspaceFormula} alt="" />
    <div className={styles.TextContainer}>{strings.DEADSPACE_HELP_2}</div>
  </div>
);
