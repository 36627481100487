import React from 'react';
import PropTypes from 'prop-types';
import {
  ListItemText, Typography,
} from '@material-ui/core';
import styles from './MetricListCard.module.scss';
import Paper from '../Paper/Paper';
import InfoButton from '../InfoButton/InfoButton';
import CopyButton from '../CopyButton/CopyButton';
import { ReactComponent as WarningIcon } from '../../icons/WarningIcon.svg';
import { TARGET_VA_WARNING } from '../../utils/strings';

const MetricListCard = ({
  title, metricList, showHelpIcons, targetVa, vaUnit,
}) => {
  const warning = targetVa < 1 || targetVa > 30;
  const displayTargetVa = targetVa < 1 ? '< 1' : targetVa;
  const targetVaIsValid = targetVa === 0 || targetVa; // all natural numbers should be valid, but 0 is falsy
  return (
    <Paper className={targetVaIsValid ? styles.SummaryCardPaper : styles.MetricListCard}>
      {targetVaIsValid && (
      <div>
        {warning && (
          <div id="target-va-warning" className={styles.TargetVaWarning}>
            <div><WarningIcon className={styles.WarningIcon} /></div>
            {TARGET_VA_WARNING}
          </div>
        )}
        <div><div className={styles.TargetVATitle}>{title}</div>
          <div className={styles.TargetVAValue}>{displayTargetVa} {vaUnit}<CopyButton targetVa={targetVa} /></div>
        </div>
      </div>
      )}
      {!targetVaIsValid
    && <div className={styles.CardTitle}>{title}</div>}

      <ul className={styles.List}>
        {metricList.map((metric) => (
          <React.Fragment key={metric.name}>
            <li id={metric.name} className={styles.MetricRow}>
              <span className={[styles.MetricRow, styles.name].join(' ')}>
                <ListItemText
                  primary={(
                    <Typography
                      className={!targetVaIsValid ? styles.MetricTitle : styles.DialogMetricTitle}
                      color="textSecondary"
                    >{metric.name}
                    </Typography>
                  )}
                  secondary={(
                    <Typography
                      className={!targetVaIsValid ? styles.MetricValue : styles.DialogMetricValue}
                      color="textPrimary"
                    >
                      <b>{metric.value}</b> {metric.unit}
                    </Typography>
                )}

                />
              </span>

              {showHelpIcons ? (
                <InfoButton
                  className={styles.HelpButton}
                  id={`info-button-${metric.name}`}
                  helpText={metric.helpText}
                >{metric.metricDialogDetails}
                </InfoButton>
              ) : null}
            </li>
            <hr className={styles.horizontalLine} />
          </React.Fragment>
        ))}
      </ul>
    </Paper>
  );
};

MetricListCard.propTypes = {
  title: PropTypes.string.isRequired,
  targetVa: PropTypes.number,
  vaUnit: PropTypes.string,
  showHelpIcons: PropTypes.bool,
  metricList: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string.isRequired,
    // value may be '' or undefined in the case that its not given - this is mostly dealt with by calculator
    value: PropTypes.oneOfType([
      PropTypes.number,
      PropTypes.oneOf(['']),
    ]),
    unit: PropTypes.string.isRequired,
  })).isRequired,
};
export default MetricListCard;
