import React from 'react';
import PropTypes from 'prop-types';

import {
  FormControl, Select, MenuItem, FormHelperText,

} from '@material-ui/core';
import styles from './FeetInchesSelector.module.scss';
import {
  FIELD_KEYS, UNITS as Units, FT_MENU_OPTIONS, IN_MENU_OPTIONS,
} from '../../utils/constants';

export const FeetInchesSelector = ({
  onHeightChange,
  ftValue,
  inValue,
  errorMessage,
}) => {
  const filterInchesDropdown = (feet) => {
    switch (feet) {
      case 4:
        return IN_MENU_OPTIONS.slice(3, 8);
      case 8:
        return IN_MENU_OPTIONS.slice(0, 3);
      default:
        return IN_MENU_OPTIONS;
    }
  };
  return (
    <>
      <div className={styles.valueContainer}>
        <FormControl classes={{ root: styles.FtControl }}>
          <Select
            labelId="ft-select"
            id={FIELD_KEYS.HEIGHT_FT}
            data-testid="ft-select"
            value={ftValue}
            onChange={(event) => onHeightChange(event.target.value, FIELD_KEYS.HEIGHT_FT)}
            label="Feet"
            inputProps={{
              name: 'Feet',
              id: 'outlined-feet-native-simple',
              classes: { root: styles.DropdownInput },
            }}
          >
            {FT_MENU_OPTIONS.map((item) => <MenuItem key={item} value={item}>{item}</MenuItem>)}
          </Select>
        </FormControl>
        <h2 className={styles.Unit}>{Units.FT}</h2>
        <FormControl>
          <Select
            labelId="in-select"
            id={FIELD_KEYS.HEIGHT_IN}
            value={inValue}
            onChange={(event) => onHeightChange(event.target.value, FIELD_KEYS.HEIGHT_IN)}
            label="Inches"
            inputProps={{
              name: 'Inches',
              id: 'outlined-inches-native-simple',
              classes: { root: styles.DropdownInput },
            }}
          >
            {filterInchesDropdown(ftValue).map((item) => <MenuItem key={item} value={item}>{item}</MenuItem>)}
          </Select>
        </FormControl>
        <h2 className={styles.Unit}>{Units.IN}</h2>

      </div>
      <FormHelperText
        id="component-error-text"
        className={styles.ErrorText}
      >{errorMessage}
      </FormHelperText>
    </>

  );
};
FeetInchesSelector.propTypes = {
  onHeightChange: PropTypes.func.isRequired,
  ftValue: PropTypes.number,
  inValue: PropTypes.number,
  errorMessage: PropTypes.string,
};
