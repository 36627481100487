import React from 'react';
import PropTypes from 'prop-types';

import styles from './Paper.module.scss';

/**
 * # Paper
 * Simple ResMed Paper Wrapper Component
 *
 * ## PropTypes:
 * ```js
 * RMPaper.propTypes = {
 *   children: PropTypes.node,
 *   className: PropTypes.string,
 * };
 * ```
 */

const RMPaper = ({ children, className }) => {
  const classes = [styles.RMPaper];
  classes.push(className);

  return (
    <div className={classes.join(' ')}>
      {children}
    </div>
  );
};

RMPaper.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
};

export default RMPaper;
