import React from 'react';

import { IconButton } from '@material-ui/core';
import PropTypes from 'prop-types';
import styles from './CloseButton.module.scss';
import { ReactComponent as CloseIcon } from '../../icons/CloseIcon.svg';

const CloseButton = ({ handleCloseAnswerDialog, persist }) => (
  <div className={styles.CloseButtonContainer}>
    <IconButton
      className={persist ? styles.CloseButtonPersist : styles.CloseButton}
      onClick={() => handleCloseAnswerDialog()}
    >
      <CloseIcon />
    </IconButton>
  </div>
);
CloseButton.propTypes = {
  handleCloseAnswerDialog: PropTypes.func.isRequired,
  persist: PropTypes.bool,
};
export default CloseButton;
