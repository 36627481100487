import React from 'react';
import PropTypes from 'prop-types';
import { Fade, IconButton } from '@material-ui/core';
import Tooltip from '@material-ui/core/Tooltip';
import styles from './CopyButton.module.scss';
import { ReactComponent as CopyIcon } from '../../icons/CopyIcon.svg';
import { COPY_TO_CLIPBOARD } from '../../utils/strings';

const CopyButton = ({ targetVa }) => (

  <Tooltip
    className={styles.ToolTip}
    TransitionComponent={Fade}
    TransitionProps={{ timeout: 300 }}
    title={`${COPY_TO_CLIPBOARD} ${targetVa}`}
    arrow
    placement="top"
  >
    <IconButton
      className={styles.CopyButton}
      onClick={() => {
        navigator.clipboard.writeText(targetVa);
      }}
    >
      <CopyIcon />
    </IconButton>
  </Tooltip>

);
CopyButton.propTypes = {
  targetVa: PropTypes.number.isRequired,
};
export default CopyButton;
