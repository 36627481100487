import {
  HEIGHT_RANGE_ERROR, EMPTY_ERROR,
  PATIENT_RATE_RANGE_ERROR,
} from './strings';
import {
  UNITS, FIELD_KEYS, METRIC_DATA, INPUT_TARGET, INITIAL_DATA,
} from './constants';
import {
  convertToFeetInches, convertToCm, deadspace,
  calculateVtkgFromVt, idealBodyWeightFromCm, calculateMVFromVt,
  calculateMVFromVtkg, calculateVtFromMV, calculateVtFromVtkg, calculateVtkgFromMV,
  calculateTargetVaFromVt, calculateTargetVaFromVtkg, calculateTargetVaFromMV,
} from './IVAPSCalculator';

export const createMetricRow = (name, value, unit, helpText, metricDialogDetails) => ({
  name,
  value,
  unit,
  helpText,
  metricDialogDetails,
});

export const checkHeight = (enteredValues, value, unit) => {
  const { heightFt, heightIn } = enteredValues;
  let error = '';
  if (unit === UNITS.FT) {
    const heightCm = convertToCm(value, heightIn);
    if (heightCm < 110 || heightCm > 250) {
      error = HEIGHT_RANGE_ERROR;
    }
  } else if (unit === UNITS.IN) {
    const heightCm = convertToCm(heightFt, value);
    if (heightCm < 110 || heightCm > 250) {
      error = HEIGHT_RANGE_ERROR;
    }
  }
  return error;
};

export const checkSelectedTarget = (value) => {
  let error = '';
  // 0 should not be considered empty
  if (!value && !(value === 0)) {
    error = EMPTY_ERROR;
  }
  return error;
};

export const checkTargetPatientRate = (value) => {
  let error = '';
  if (!value && !(value === 0)) {
    error = EMPTY_ERROR;
  } else if (value < 8 || value > 30) {
    error = PATIENT_RATE_RANGE_ERROR;
  }
  return error;
};

export const validate = (enteredValues, id, value) => {
  let message = '';
  let key = id;
  switch (id) {
    case FIELD_KEYS.HEIGHT_FT:
      message = checkHeight(enteredValues, value, UNITS.FT);
      break;
    case FIELD_KEYS.HEIGHT_IN:
      message = checkHeight(enteredValues, value, UNITS.IN);
      key = FIELD_KEYS.HEIGHT_FT; // We only want one key for errors relating to height in Ft/In
      break;
    case FIELD_KEYS.HEIGHT_CM:
      break;
    case FIELD_KEYS.PATIENT_RATE:
      message = checkTargetPatientRate(value);
      break;
    case FIELD_KEYS.VTKG:
    case FIELD_KEYS.MV:
    case FIELD_KEYS.VT:
      message = checkSelectedTarget(value);
      break;
    default:
      console.warn('Unexpected id, could not validate');
  }
  return {
    key,
    message,
  };
};

export const getOtherMetricsList = (selectedTarget, state) => Object.values(METRIC_DATA)
  .filter((metric) => metric.name !== selectedTarget && metric.includeInMetricList)
  .map((item) => createMetricRow(item.name, state[item.key], item.unit, item.helpText,
    item.metricDialogDetails, item.icon));

export const getAllMetricsList = (selectedTarget, state) => Object.values(METRIC_DATA)
  .map((item) => createMetricRow(item.name, state[item.key], item.unit,
    item.helpText, item.metricDialogDetails, item.icon));

export const getHeightErrorMessage = (errors) => {
  const heightErrorKeys = Object.keys(errors)
    .filter((e) => e === FIELD_KEYS.HEIGHT_FT || e === FIELD_KEYS.HEIGHT_IN)
    .map((key) => errors[key])
    .filter((msg) => msg !== '');
  if (heightErrorKeys.length === 0) {
    return '';
  }
  const error = heightErrorKeys[0];
  return error;
};

export const calculateAssociatedValues = (enteredValues, stateKey, value) => {
  const updatedStateObject = { ...enteredValues, [stateKey]: value };
  const {
    heightCm, heightFt, heightIn, selectedTarget, mv, vt, vtkg, patientRate, selectedHeightUnit,
  } = updatedStateObject;
  let updatedMv = mv;
  let updatedVt = vt;
  let updatedVtkg = vtkg;
  let updatedHeightCm = heightCm;
  let updatedHeightFt = heightFt;
  let updatedHeightIn = heightIn;

  if (selectedHeightUnit === UNITS.FT) {
    updatedHeightCm = convertToCm(heightFt, heightIn);
  } else {
    const height = convertToFeetInches(updatedHeightCm);
    updatedHeightFt = height.feet;
    updatedHeightIn = height.inches;
  }

  const updatedIBW = idealBodyWeightFromCm(updatedHeightCm);
  const updatedDS = deadspace(updatedHeightCm);

  switch (selectedTarget) {
    case INPUT_TARGET.vt.name:
      updatedMv = calculateMVFromVt(vt, patientRate);
      updatedVtkg = calculateVtkgFromVt(vt, updatedHeightCm);
      break;
    case INPUT_TARGET.vtkg.name:
      updatedMv = calculateMVFromVtkg(vtkg, patientRate, updatedHeightCm);
      updatedVt = calculateVtFromVtkg(vtkg, updatedHeightCm);
      break;
    case INPUT_TARGET.mv.name:
      updatedVt = calculateVtFromMV(mv, patientRate);
      updatedVtkg = calculateVtkgFromMV(mv, patientRate, updatedHeightCm);
      break;
    default:
      console.warn('Unexpected selected target, could not update values');
  }
  return {
    ...updatedStateObject,
    [FIELD_KEYS.HEIGHT_CM]: updatedHeightCm,
    [FIELD_KEYS.HEIGHT_FT]: updatedHeightFt,
    [FIELD_KEYS.HEIGHT_IN]: updatedHeightIn,
    [FIELD_KEYS.HEIGHT_CM]: updatedHeightCm,
    [FIELD_KEYS.MV]: updatedMv,
    [FIELD_KEYS.VT]: updatedVt,
    [FIELD_KEYS.VTKG]: updatedVtkg,
    [FIELD_KEYS.DEADSPACE]: updatedDS,
    [FIELD_KEYS.IDEAL_BW]: updatedIBW,
  };
};

export const calculateTargetVa = (enteredValues) => {
  const {
    heightCm, selectedTarget, mv, vt, vtkg, patientRate,
  } = enteredValues;
  let targetVa = undefined;
  switch (selectedTarget) {
    case INPUT_TARGET.vt.name:
      targetVa = calculateTargetVaFromVt(heightCm, patientRate, vt);
      break;
    case INPUT_TARGET.vtkg.name:
      targetVa = calculateTargetVaFromVtkg(heightCm, patientRate, vtkg);
      break;
    case INPUT_TARGET.mv.name:
      targetVa = calculateTargetVaFromMV(heightCm, patientRate, mv);
      break;
    default:
      console.error('Error: selected target not defined. Target Va could not be calculated.');
  }
  return targetVa;
};

export const resetTargetValues = (selectedTargetValue, currentState, currentErrors) => {
  const updatedErrors = {
    ...currentErrors, [FIELD_KEYS.MV]: '', [FIELD_KEYS.VT]: '', [FIELD_KEYS.VTKG]: '',
  };
  const updatedObject = {
    ...currentState,
    [FIELD_KEYS.SELECTED_TARGET]: selectedTargetValue,
    [FIELD_KEYS.MV]: INITIAL_DATA.mv,
    [FIELD_KEYS.VT]: INITIAL_DATA.vt,
    [FIELD_KEYS.VTKG]: INITIAL_DATA.vtkg,
  };
  return {
    updatedErrors, updatedObject,
  };
};
